.footer-box {
  min-height: 30vh;
  text-align: left;
  padding: 3rem;
  font-weight: 400;
  color: #fff;
}

.footer-box-link{
  color: #fff;
}

.footer-detail {
  margin-top: 1rem;
  color: #c9c9c9;
  font-size: 0.7rem;
  line-height: 1.2rem;
}

.footer-detail:hover {
  color: #6abae9;
}

.footer-move:hover{
  cursor: pointer;
  padding-left: 0.2rem;
  transition: all .3s linear 0s;
}
